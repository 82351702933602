import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
//Modal
import { StandardDialogActions } from '../Modals/StandardDialog';
import StandardDialog from '../Modals/StandardDialog';
// Icons
import DownloadIcon from '@mui/icons-material/Download';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SearchIcon from '@mui/icons-material/Search';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// Material-UI
import { Box, Button, Card, CardContent, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';

import PartnerResource from './PartnerResource';
import { PartnerResource as PartnerResourceInterface, PartnerResourceMetadata } from '../../interfaces/partnerResources.interface';
import { getHelpURL } from '../../services/user';
import { useAppSelector } from '../../app/hooks';
import { useTheme } from '@mui/material/styles';
import ResourceCard from './ResourceCard';

const gradientColors = [
  { start: '#2C3E50', end: '#4CA1AF' },  // Dark Blue to Teal
  { start: '#34495E', end: '#2C3E50' },  // Slate Gray to Charcoal Blue
  { start: '#1F1C2C', end: '#928DAB' },  // Dark Violet to Soft Gray
  { start: '#2E4053', end: '#4B79A1' },  // Dark Slate to Steel Blue
  { start: '#0F2027', end: '#203A43' },  // Dark Teal to Deep Ocean Blue
  { start: '#3A6073', end: '#16222A' },  // Slate Blue to Dark Teal
];

export default function PartnerResourcesModal({
  partnerResources,
  showResourceModal,
  fetchResources,
  handleCloseResourceModal,
}: {
  partnerResources: PartnerResourceInterface[],
  showResourceModal: boolean,
  handleCloseResourceModal: () => void,
  fetchResources: () => void,
}) {
  const [expandedSection, setExpandedSection] = useState<string | null>('POLICIES');
  const [partnerResource, setPartnerResource] = useState<any | null>(null);
  const { resourceSection, documentId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAppSelector(state => state.user);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Must go back to the main resource page if a search query is entered
    if (expandedSection !== 'POLICIES') {
      setExpandedSection('POLICIES');
    }
    navigate('/partner-resources/POLICIES');
    if (partnerResource !== null) setPartnerResource(null);
    setSearchQuery(event.target.value.toLowerCase());
  };

  const resourceSections = useMemo(() => partnerResources.reduce((acc, resource) => {
    if (!acc[resource.category.title]) acc[resource.category.title] = [];
    acc[resource.category.title.toLocaleUpperCase()].push(resource);
    return acc;
  }, {
    'POLICIES': [],
  } as { [key: string]: PartnerResourceInterface[] }), [partnerResources]);

  // Filter sections based on the search query
  const filteredSections = expandedSection && resourceSections[expandedSection]
    ? resourceSections[expandedSection].filter((item) =>
      item.title.toLowerCase().includes(searchQuery) || item.description.toLowerCase().includes(searchQuery),
    )
    : [];

  const handleSectionClick = (section: string) => {
    setExpandedSection(section);
    navigate(`/partner-resources/${section}`);
  };

  const handleCloseEduCenter = () => {
    handleCloseResourceModal();
  };

  const handleUpdateResource = useCallback((resource: PartnerResourceInterface) => {
    setPartnerResource(resource);
  }, []);

  const handleCardClick = (document: any) => {
    navigate(`/partner-resources/${expandedSection}/${document.id}`);
  };

  const handleBackClick = useCallback(() => {
    setPartnerResource(null);
    navigate(`/partner-resources/${expandedSection}`);
  }, [documentId, expandedSection]);

  const uniqueDownloadCount = useCallback((metadata: PartnerResourceMetadata[]) => {
    const uniqueUsers = new Set();
    return metadata.reduce((acc, meta) => {
      if (uniqueUsers.has(meta.downloadedByUser)) return acc;
      uniqueUsers.add(meta.downloadedByUser);
      acc++;
      return acc;
    }, 0);
  }, [user]);

  useEffect(() =>  {
    fetchResources();
  }, [documentId, partnerResource]);

  useEffect(() => {
    if (showResourceModal) {
      setExpandedSection('POLICIES');
      setPartnerResource(null);
      setSearchQuery('');
    } else {
      navigate('/partner-resources');
    }
    return () => {
      setSearchQuery('');
    };
  }, [showResourceModal]);

  useEffect(() => {
    if (documentId && expandedSection && resourceSections[expandedSection]) {
      // If current url is /partner-resources exit early
      if (window.location.pathname === '/partner-resources') return;
      const selectedDoc = resourceSections[expandedSection].find((doc) => doc.id === parseInt(documentId));
      if (selectedDoc) setPartnerResource(selectedDoc);
    }

    if (!documentId && partnerResource) setPartnerResource(null);
  }, [documentId, expandedSection, showResourceModal]);

  useEffect(() => {
    if (resourceSection && window.location.pathname !== '/partner-resources') {
      setExpandedSection(resourceSection);
    }
  }, [resourceSection]);

  return (
        <StandardDialog
          maxWidth="xl"
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocalLibraryIcon fontSize="small" />
              Partner Resource Center
            </Box>
          }
          isOpen={showResourceModal}
          handleClose={handleCloseEduCenter}>
        <Grid container sx={{ minHeight: '470px' }}>
            <Grid item xs={2} sx={{ borderRight: '1px solid #f1f4f6', padding: 2 }}>
                <Typography variant="h6">Library</Typography>
                <List component="nav">
                    <ListItem
                        button
                        onClick={() => handleSectionClick('POLICIES')}
                        sx={{
                          borderRadius: '4px',
                          backgroundColor: expandedSection === 'POLICIES' ? '#ECEFF1' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#f1f4f6',
                          },
                        }}
                    >
                        <ListItemText primary="Policies" />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={10} sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search by title or description..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          sx: { height: '45px' },
                        }}
                        sx={{
                          width: '50%',
                          minWidth: '300px',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#e8e8e8',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                            },
                          },
                          borderRadius: '4px',
                        }}
                    />
                    <a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer">
                        <Button
                            variant="text"
                            startIcon={<ReviewsIcon />}
                            sx={{ ml: 2, px: '20px' }}
                            onClick={()=> { return false; }}
                        >
                            Feedback
                        </Button>
                    </a>
                </Box>
                { (!documentId && !partnerResource) && (
                    <Box>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 500, px: 1 }}>
                            {expandedSection && expandedSection?.charAt(0).toUpperCase() + expandedSection?.slice(1).toLowerCase()}
                        </Typography>
                        <Grid container spacing={3}>
                        {filteredSections.map((item, index) => (
                          <Grid item xs={12} sm={6} md={3} key={item.id}>
                              <Card
                                  elevation={0}
                                  sx={{
                                    height: 325,
                                    border: '1px solid #eaeaea',
                                    boxSizing: 'border-box',
                                    padding: 2,
                                    borderRadius: 2,
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    transition: 'transform 0.3s, box-shadow 0.3s, border-color 0.3s',
                                    transformOrigin: 'center',
                                    '&:hover': {
                                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                                      borderColor: '#eeeeee',
                                    },
                                  }}
                                  onClick={() => handleCardClick(item)}
                              >
                                  <Box
                                      sx={{
                                        background: `linear-gradient(to bottom left, ${gradientColors[index % gradientColors.length].start}, ${gradientColors[index % gradientColors.length].end})`,
                                        padding: 1,
                                        borderRadius: 1,
                                      }}
                                  >
                                      <ResourceCard item={item} />
                                  </Box>
                                  <CardContent
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        position: 'relative',
                                        flex: 1,
                                        height: 'calc(100% - 140px)',
                                        pt: 3,
                                        px: 1,
                                      }}
                                  >
                                      <Box sx={{ flexGrow: 1 }}>
                                          <Typography variant="h6" sx={{ fontWeight: '500', lineHeight: 1 }}>
                                              {item.title}
                                          </Typography>
                                          <Typography variant="caption" sx={{ marginLeft: '5px', fontWeight: '400' }}>
                                            by { item?.author?.displayName || 'Trusted Partner Network' }
                                          </Typography>
                                          <Typography variant="body2" sx={{ marginTop: 1 }}>
                                              {item.subDescription}
                                          </Typography>
                                      </Box>
                                      { user?.type === 'tpn_admin' && (
                                      <Box sx={{ justifyContent: 'space-between', bottom: 5, position: 'absolute' }}>
                                          <Typography variant="caption" sx={{ color: theme.palette.customThemeColors?.darkGrey }}>
                                              <DownloadIcon sx={{ fontSize: 'small', verticalAlign: 'middle', mr: 0.5 }} />
                                              {uniqueDownloadCount(item.metadata)} Download{uniqueDownloadCount(item.metadata) === 1 ? '' : 's'}
                                          </Typography>
                                      </Box>
                                      )}
                                  </CardContent>
                              </Card>
                          </Grid>
                        ))}
                        </Grid>
                    </Box>
                )}
                { partnerResource && (
                  <PartnerResource uniqueDownloadCount={uniqueDownloadCount} handleUpdateResource={handleUpdateResource} partnerResource={partnerResource} handleBackClick={handleBackClick} />
                )}
            </Grid>
        </Grid>
        <StandardDialogActions>
            <Button variant="outlined" onClick={handleCloseResourceModal}>Close</Button>
        </StandardDialogActions>
    </StandardDialog>
  );
}