import React, { useMemo, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { PartnerResource as PartnerResourceInterface, PartnerResourceMetadata, ResourceImageType } from '../../interfaces/partnerResources.interface';
import { postDownloadPartnerResource } from '../../services/partnerResources';
import { useAppSelector } from '../../app/hooks';
import { useTheme } from '@mui/material/styles';

export default function PartnerResource({
  partnerResource,
  handleBackClick,
  handleUpdateResource,
  uniqueDownloadCount,
}: {
  partnerResource: PartnerResourceInterface;
  handleUpdateResource: (resource: PartnerResourceInterface) => void;
  handleBackClick: () => void;
  uniqueDownloadCount: (metaData: PartnerResourceMetadata[]) => number;
}) {
  const { user } = useAppSelector((state) => state.user);
  const theme = useTheme();
  const resourceImage = useMemo(() => partnerResource.images.find((image) => image.type === ResourceImageType.IMAGE) || partnerResource.images[0], [partnerResource.images]);
  const resourceDoc = useMemo(() => partnerResource.images.find((image) => image.type === ResourceImageType.DOCUMENT), [partnerResource.images]);
  const handleDownloadPartnerResource = useCallback(async () => {
    if (!partnerResource.id || !user || !resourceDoc) return;

    try {
      const metaData = await postDownloadPartnerResource({
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        downloadedByUser: user.id,
        resource: partnerResource.id as number,
      });

      if (!metaData) return;

      handleUpdateResource({
        ...partnerResource,
        metadata: [...partnerResource.metadata, metaData],
      });

      // Ensure the file is downloadable and correct
      const link = document.createElement('a');
      link.href = resourceDoc.image;
      link.setAttribute('download', partnerResource.title || 'download.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }, [partnerResource, user, resourceDoc, handleUpdateResource]);

  const hasDownloaded = useMemo(() => user && partnerResource.metadata.some((meta) => meta.downloadedByUser === user.id), [partnerResource.metadata, user]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button variant="text" sx={{ px: '30px' }} startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
          Back
        </Button>
      </Box>
      {/* Top Row: Title, Count, and Download Button */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}
          >
            {partnerResource.title}
            { user?.type === 'tpn_admin' && (
              <>
                <Box
                  component="span"
                  sx={{
                    width: '2px',
                    height: '30px',
                    backgroundColor: theme.palette.customThemeColors?.darkGrey,
                    mx: 1,
                  }}
                />
                <Box
                  component="span"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.customThemeColors?.darkGrey,
                    fontSize: '1rem',
                    fontWeight: '300',
                  }}
                >
                  <DownloadIcon sx={{ fontSize: '1rem' }} />
                  <Typography sx={{ fontWeight: '600' }}>{uniqueDownloadCount(partnerResource.metadata)}</Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: 'inherit',
                      ml: 0.5,
                    }}
                  >
                    user{uniqueDownloadCount(partnerResource.metadata) === 1 ? '' : 's'}
                  </Typography>
                </Box>
              </>
            )}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={!resourceDoc?.image}
            onClick={handleDownloadPartnerResource}
            startIcon={hasDownloaded ? <DownloadDoneIcon /> : <DownloadIcon />}
          >
            Download
          </Button>
        </Box>
      </Box>
    
        {/* Second Row: Description */}
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1rem',
            lineHeight: 1.5,
            textAlign: 'left',
            maxWidth: '70%',
            mb: 2,
          }}
        >
          {partnerResource.description}
        </Typography>
      </Box>
  
      {/* Third Row: Image Preview */}
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{
            maxHeight: '500px',
            maxWidth: '100%',
            objectFit: 'contain',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}
          src={resourceImage?.image}
          alt="Document preview"
        />
      </Box>
    </Box>
  ); 
}