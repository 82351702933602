import { Application, ApplicationVersion, Badge, Version } from './users.interface';

export const WorkflowTypeHierarchy = {
  'tpn_best_practices_5.1': 5.1,
  'tpn_best_practices_5.2': 5.2,
  'tpn_best_practices_5.3': 5.3,
} as const;

export type WorkflowTypeKey = keyof typeof WorkflowTypeHierarchy;

export interface MiniUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Workflow {
  id: number;
  createdAt?: string;
  updatedAt?: string;
  baseline?: number;
  bpSurvey?: number;
  assessment?: number | null;
  assessmentSurvey?: number | null;
  serviceProvider: number;
  assessor?: number | null;
  assignedAssessor?: number | MiniUser | null;
  site?: number;
  status: string;
  applicationVersion?: number | ApplicationVersion;
  reportIssueDate?: string | null;
  workflowType: WorkflowTypeKey;
  isActive: boolean;
  badges?: Badge[] | number[];
  application?: number | Application;
  version?: number | Version;
}

export interface WorkflowType {
  id: number;
  title: string;
  workflowDefinition: number;
}
