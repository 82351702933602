import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import User from '../../../interfaces/users.interface';
import { Question } from '../../../interfaces/question.interface';
import { Assessment, Comment } from '../../../interfaces/assessment.interface';
import { FileUpload } from '../../Forms/FileUpload';
import TooltipWithLabel from './TooltipWithLabel';  // Import the reusable component

type AttachmentFormAndCommentProps = {
  user: User | undefined;
  question: Question;
  comments: Comment[] | undefined;
  assessment: Assessment | undefined;
  assessorFinding: string;
  assessorAnswer: string;
  files: FileUpload[];
  register: UseFormRegister<any>;
  readOnly: boolean;
  preview: boolean;
  submitted: boolean;
  onAttachmentModalOpen: () => void;
  onCommentModalOpen: () => void;
  onAssessorFindingsModalOpen: () => void;
  surveyComplete: boolean;
};

const AttachmentFormAndComment = (props: AttachmentFormAndCommentProps) => {
  const {
    user,
    question,
    comments,
    assessment,
    assessorFinding,
    assessorAnswer,
    files,
    register,
    readOnly,
    preview,
    submitted,
    onAttachmentModalOpen,
    onCommentModalOpen,
    onAssessorFindingsModalOpen,
    // surveyComplete,
  } = props;

  const tooltipText = (
    <>
      <div style={{ marginBottom: '0' }}>
        Please provide any of the following:
      </div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '25px', lineHeight: '1.5', marginTop: 1 }}>
        <li>Implementation details (e.g., timeline, specifications)</li>
        <li>Explain why the control or component has not been implemented</li>
        <li>Describe why the control or component is not applicable</li>
        <li>Describe any compensating controls you have implemented</li>
      </ul>
    </>
  );

  return (
    <>
      {(question.includeAttachmentForm || question.includeComment) &&
        question.type && question.type.includes('text') && (
          <Grid item xs={12} container spacing={2}>
            {question.includeComment && (
              <Grid item xs={question.includeAttachmentForm && user?.type !== 'content_owner' ? 8 : 12}>
                <TooltipWithLabel
                  label="Additional Details"
                  tooltipText={typeof(question.questionnaire) !== 'number' && (question.questionnaire?.type === 'site_baseline' || question.questionnaire?.type === 'application_baseline') ? question.commentLabel : tooltipText}
                />
                <TextField
                  id={`${question.id}-comment`}
                  multiline
                  rows={5}
                  fullWidth
                  InputProps={{
                    readOnly: readOnly && (!preview || submitted),
                    sx: { overflowY: 'scroll' },
                  }}
                  {...register('comment')}
                />
              </Grid>
            )}
            {question.includeAttachmentForm && (
              <Grid item xs={question.includeComment ? 4 : 6}>
                <Button
                  variant='contained'
                  startIcon={<AttachFile />}
                  id={`${question.id}-attachment`}
                  onClick={onAttachmentModalOpen}
                >
                  Attachments ({files.length})
                </Button>
              </Grid>
            )}
            {assessment && (
              <Button variant='contained' onClick={onCommentModalOpen} sx={{ mr: 1 }}>
                Comments ({comments ? comments.length : 0})
              </Button>
            )}
            {(assessorFinding || assessorAnswer) && (
              <Button variant='contained' onClick={onAssessorFindingsModalOpen} sx={{ mr: 1 }}>
                Assessors Findings
              </Button>
            )}
          </Grid>
      )}
      {(question.includeAttachmentForm || question.includeComment) &&
        question.type && !question.type.includes('text') && (
          <>
            {question.includeComment && (
              <Grid item xs={12} md={6}>
                <TooltipWithLabel
                  label="Additional Details"
                  tooltipText={typeof(question.questionnaire) !== 'number' && (question.questionnaire?.type === 'site_baseline' || question.questionnaire?.type === 'application_baseline') ? question.commentLabel : tooltipText}
                />
                <TextField
                  id={`${question.id}-comment`}
                  multiline
                  rows={5}
                  fullWidth
                  {...register('comment')}
                  InputProps={{
                    readOnly: readOnly && (!preview || submitted),
                    sx: { overflowY: 'scroll' },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
              {question.includeAttachmentForm && (
                <Button
                  variant='contained'
                  startIcon={<AttachFile />}
                  id={`${question.id}-attachment`}
                  onClick={onAttachmentModalOpen}
                >
                  Attachments ({files.length})
                </Button>
              )}
              {assessment && (
                <Button variant='contained' onClick={onCommentModalOpen} sx={{ mr: 1 }}>
                  Comments ({comments ? comments.length : 0})
                </Button>
              )}
              {(assessorFinding || assessorAnswer) && (
                <Button variant='contained' onClick={onAssessorFindingsModalOpen} sx={{ mr: 1 }}>
                  Assessors Findings
                </Button>
              )}
            </Grid>
          </>
      )}
      {!question.includeAttachmentForm && !question.includeComment && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {assessment && (
            <Button variant='contained' onClick={onCommentModalOpen} sx={{ mr: 1 }}>
              Comments ({comments ? comments.length : 0})
            </Button>
          )}
        </Grid>
      )}
    </>
  );
};

export default AttachmentFormAndComment;
