import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import CardMedia from '@mui/material/CardMedia';
import { PartnerResource } from '../../interfaces/partnerResources.interface';
import { ResourceImageType } from '../../interfaces/partnerResources.interface';

const ResourceCard = ({ item }: { item: PartnerResource }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageSrc = item.images.find((image) => image.type === ResourceImageType.IMAGE)?.image || '';

  return (
    <div>
      {!imageLoaded && <Skeleton variant="rectangular" height={130} sx={{ borderRadius: 1 }} />}
      <CardMedia
        component="img"
        height="130"
        image={imageSrc}
        alt="resource image"
        sx={{
          objectFit: 'contain',
          display: imageLoaded ? 'block' : 'none',
          borderRadius: 5,
        }}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(true)}
      />
    </div>
  );
};

export default ResourceCard;
