import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DownloadIcon from '@mui/icons-material/Download';

import { wfHierarchy, WORKFLOW_STATUSES } from '../../services/workflow';
import { Workflow } from '../../interfaces/workflow.interface';
import { Badge, Company, Contact } from '../../interfaces/users.interface';
import SurveyReportMenuItemOption from '../Surveys/SurveyReportMenuItemOption';
import AssessmentReportMenuItemOption from '../Assessments/AssessmentReportMenuItemOption';
import { useAppSelector } from '../../app/hooks';
import { generateGoldShield } from '../../services/badge';
import useFontLoader from './UseFontLoader';
import CircularProgress from '@mui/material/CircularProgress';

export default function WorkflowDownloadsMenuButton(props: { workflow: Workflow, company: Company, site?: Contact }) {
  const {
    workflow,
    company,
    site,
  } = props;
  const { user } = useAppSelector((state) => state.user);
  const [shouldRenderBlueShieldMenuItem, setShouldRenderBlueShieldMenuItem] = useState(false);
  const [shouldRenderGoldShieldMenuItem, setShouldRenderGoldShieldMenuItem] = useState(false);
  const [shouldRenderGoldBadgeMenuItem, setShouldRenderGoldBadgeMenuItem] = useState(false);
  const [showGoldBadgeLoadingIcon, setShowGoldBadgeLoadingIcon] = useState(false);
  const blueShieldReportAvailableWorkflowStatuses: string[] = wfHierarchy.filter(
    (status) =>
      status !== WORKFLOW_STATUSES.INCOMPLETE &&
      status !== WORKFLOW_STATUSES.NON_COMPLIANT,
  );

  const theme = useTheme();
  const loadFont = useFontLoader('Montserrat', 700);

  const handleGenerateGoldShield = async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!workflow || !company) return;
    const isFontLoaded = await loadFont();
    if (!isFontLoaded) return;

    generateGoldShield({
      company,
      site: site || company.locations?.find(loc => loc.id ===  workflow?.site),
      workflow,
    });
    setShowGoldBadgeLoadingIcon(false);
  };

  const handleClickGoldBadgePDF = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowGoldBadgeLoadingIcon(true);
    handleGenerateGoldShield(e);
  };

  useEffect(() => {
    if (workflow?.status && user?.type !== 'assessor' && ['Complete', 'Completed', 'Remediation'].includes(workflow?.status)) {
      setShouldRenderGoldShieldMenuItem(true);
    }
  }, [user, workflow]);

  useEffect(() => {
    if (
      blueShieldReportAvailableWorkflowStatuses.includes(workflow.status) &&
      (workflow.badges as Badge[]).some(badge => badge.title === 'tpn_self_reported')
    ) {
      setShouldRenderBlueShieldMenuItem(true);
    }
    if (workflow?.badges?.some(badge => typeof badge !== 'number' && badge.title === 'tpn_assessed')) setShouldRenderGoldBadgeMenuItem(true);
  }, [workflow]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback((e?: any) => {
    e?.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleToggleViewDropdown = useCallback(
    (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  return (
    <>
      <Button
        sx={{
          maxWidth: '100%',
          width: '205px',
          minWidth: '170px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          bgcolor: 'transparent',
        }}
        size="small"
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleToggleViewDropdown}
      >
        DOWNLOAD OPTIONS
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ marginTop: '5px' }}
      >
        <MenuItem
          disableRipple
          sx={{
            cursor: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              cursor: 'default',
              backgroundColor: 'transparent',
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.customThemeColors?.lightGray,
              width: '100%',
              fontWeight: 500,
              fontSize: '10px',
            }}
          >
            DOWNLOAD OPTIONS
          </Typography>
        </MenuItem>
        {shouldRenderBlueShieldMenuItem && (
          <SurveyReportMenuItemOption surveyId={workflow?.bpSurvey} />
        )}
        {shouldRenderGoldShieldMenuItem && (
          <AssessmentReportMenuItemOption
            assessmentId={workflow?.assessment}
            surveyId={workflow?.bpSurvey}
          />
        )}
        {shouldRenderGoldBadgeMenuItem && (
          <MenuItem onClick={handleClickGoldBadgePDF}>
            <ListItemIcon>
              <ListItemIcon>
                {showGoldBadgeLoadingIcon ? <CircularProgress size='20px' /> : <DownloadIcon fontSize="small" />}
              </ListItemIcon>
            </ListItemIcon>
            <ListItemText primary='TPN Gold Status PDF' />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
