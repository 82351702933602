// react and external-libs
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//redux
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Workflow } from '../../interfaces/workflow.interface';
 
import { resetAssessment } from '../../app/store/assessmentSlice';
import { resetSurvey } from '../../app/store/surveySlice';
import { SURVEY_ACTIONS_LABELS } from '../../services/surveyHelpers';
import { workflowVersion } from '../../services/workflow';
 
 type ViewSurveyButtonProps = {
   workflow: Workflow;
   actionLabel: string;
   actionIcon?: React.ReactNode;
   limit?: string;
   handleVisitAssessment?: () => void;
   handlePreLoad?: () => void;
 };
 
const ViewSurveyButton = (props: ViewSurveyButtonProps) => {
  const { workflow, handleVisitAssessment, limit, actionLabel, handlePreLoad, actionIcon } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showViewDropdown, setShowViewDropdown] = useState(false);
  const theme = useTheme();
 
  const handleClose = useCallback((e?: any) => {
    e?.stopPropagation();
    setAnchorEl(null);
  }, []);
 
  const handleToggleViewDropdown = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setShowViewDropdown(!showViewDropdown);
  }, [showViewDropdown]);

  const handleClearPreLoad = useCallback(() => {
    dispatch(resetAssessment());
    dispatch(resetSurvey());
  }, [dispatch]);

  const handleViewBaseline = (e: any) => {
    e.stopPropagation();
    handleClearPreLoad();
    handleClose();
    navigate(`/questionnaire/${workflow?.baseline}/`);
  };
 
  const handleViewBP = useCallback((e: any) => {
    e.stopPropagation();

    // If custom navigation logic exists, run it and exit early
    if (handleVisitAssessment) {
      handleVisitAssessment();
      handleClose();
      return;
    }

    handleClose();

    window.location.href = `/questionnaire/${workflow?.bpSurvey}/`;
  }, [workflow, handleVisitAssessment, handleClose]);

  const showBaseline = useMemo(() => {
    return (((user?.type === 'vendor_admin' || user?.type === 'tpn_admin') && workflow?.bpSurvey) ||
     workflow?.assessmentSurvey ||
     (workflow?.bpSurvey && workflow?.status === 'submittedForAssessment')) &&
     workflow?.baseline && (user?.type !== 'assessor' ||
     ['Complete', 'Completed', 'Approved', 'Remediation'].indexOf(workflow?.status) === -1); 
  }, [workflow, user]);
 
  const version = useMemo(() => {
    return workflowVersion(workflow?.workflowType);
  }, [workflow]);
 
  return (
     <>
     {user && (
         <Button
         sx={{
           maxWidth: '100%',
           width: '205px',
           minWidth: '170px',
           paddingLeft: '10px',
           paddingRight: '10px',
           textAlign: 'center',
           bgcolor: Boolean(anchorEl) ? '#EFF8FF' : 'transparent',
         }}
         size='small'
         variant='outlined'
         endIcon={<ArrowDropDownIcon />}
         onClick={handleToggleViewDropdown}
         >
         Questionnaire{!limit && (workflow.bpSurvey && workflow.baseline) && 's'}
         </Button>
     )}
     <Menu
         anchorEl={anchorEl}
         open={Boolean(anchorEl)}
         onClose={handleClose}
         sx={{ marginTop: '5px' }}
     >
         <MenuItem
         disableRipple
         onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
         sx={{
           cursor: 'none',
           backgroundColor: 'transparent',
           '&:hover': {
             cursor: 'default',
             backgroundColor: 'transparent',
           },
         }}>
         <Typography variant="body2"
     sx={{
       color: theme.palette.customThemeColors?.lightGray,
       width: '100%',
       fontWeight: 500,
       fontSize: '10px',
     }}>
           QUESTIONNAIRE OPTIONS
         </Typography>
       </MenuItem>
         { (workflow?.baseline || showBaseline) && limit !== 'Best Practices' && (
         <MenuItem onClick={handleViewBaseline}>
             <ListItemIcon>
                <QuestionAnswerIcon sx={{ color: 'inherit' }} fontSize="small" />
             </ListItemIcon>
             <ListItemText primary="Scoping Baseline" />
         </MenuItem>
         )}
         { workflow?.bpSurvey && limit !== 'Baseline' && (
         <MenuItem onClick={handleViewBP} onMouseEnter={handlePreLoad && handlePreLoad}>
             <ListItemIcon >
                 {actionIcon}
             </ListItemIcon>
             <ListItemText primary={actionLabel === SURVEY_ACTIONS_LABELS.viewQuestionnaire ? `Best Practice ${version}` : actionLabel} />
         </MenuItem>
         )}
     </Menu>
     </>
  );
};
 
export default ViewSurveyButton;