import React, { useMemo } from 'react';
// redux
import { useAppSelector } from '../../app/hooks';
import { useTheme } from '@mui/material/styles';
// interface
import { Badge, Company } from '../../interfaces/users.interface';
import { Survey } from '../../interfaces/survey.interface';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
// services
import { assessmentDisplayLogic, getRemediationCounts, surveyDisplayLogic } from '../../services/surveyHelpers';
// components
import { AssessmentSurveyAuditLogs } from '../Assessments/AssessmentAuditLog';
import { Workflow } from '../../interfaces/workflow.interface';
import { Typography } from '@mui/material';
import moment from 'moment';

type WorkflowStatusProps = {
  workflow?: Workflow;
  company: Company;
  survey?: Survey;
  assessmentSurvey?: AssessmentSurvey;
  hasBlueExpired?: boolean;
};

const WorkflowStatus = (props: WorkflowStatusProps) => {
  const theme = useTheme();
  const { workflow, company, survey, assessmentSurvey, hasBlueExpired } = props;
  // redux
  const { user } = useAppSelector((state) => state.user);
  const expiration = useMemo(() => {
    if (!workflow || workflow.isActive) return false;
    if ((workflow.badges as Badge[])?.find(b => b.title === 'tpn_assessed')) return (workflow.badges as Badge[])?.find(b => b.title === 'tpn_assessed')?.expirationDate;
    if ((workflow.badges as Badge[])?.find(b => b.title === 'tpn_self_reported')) return (workflow.badges as Badge[])?.find(b => b.title === 'tpn_self_reported')?.expirationDate;
    return '';
  }, [workflow]);

  const surveyStatusDisplay = React.useMemo(() => surveyDisplayLogic({
    survey,
    companyCerts: company.companyCertifications,
    workflow,
  }), [survey, company, workflow]);

  return (
    <>
      {expiration && <Typography>Expired {moment(expiration).format('MM/DD/YYYY')}</Typography>}
      {!!workflow?.assessmentSurvey && workflow?.isActive && (
        <AssessmentSurveyAuditLogs assessmentSurveyId={workflow?.assessmentSurvey} status={workflow?.status} />
      )}
      {!workflow || workflow.isActive
        ? workflow?.assessmentSurvey
          ? workflow?.status === 'Remediation' || (workflow?.status === 'Complete' && assessmentSurvey?.bestPracticeRemediationCount) && user && user.type !== 'assessor'
            ?  getRemediationCounts({ assessmentSurvey, themeProp: theme })
            : <Typography sx={{ color: theme.palette.customThemeColors?.darkGrey }}>
              {assessmentDisplayLogic(workflow?.status)}
              </Typography>
          : 
          <Typography sx={{ color: theme.palette.customThemeColors?.darkGrey }}>{surveyStatusDisplay}</Typography>
        : ''}
        {hasBlueExpired && <Typography sx={{ color: theme.palette.customThemeColors?.darkGrey }}>Questionnaire Expired</Typography>}
    </>
  );
};

export default WorkflowStatus;
