import React, { useCallback, useState, useEffect } from 'react';
import Layout from '../Layout/Layout';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PartnerResourcesModal from './PartnerResourcesModal';
import fetchParterResources from '../../services/partnerResources';
import { PartnerResource } from '../../interfaces/partnerResources.interface';
import { useNavigate } from 'react-router-dom';

const PartnerResources = () => {
  const navigate = useNavigate();
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [partnerResources, setPartnerResources] = useState<PartnerResource[]>([]);
  const [loadingResources, setLoadingResources] = useState(true);

  const handleCloseResourceModal = useCallback(() => {
    setShowResourceModal(false);
  }, []);

  const fetchResources = useCallback(async () => {
    try {
      const { results } = await fetchParterResources();
      setLoadingResources(false);
      if (!results) return;
      setPartnerResources(results);
    } catch (error) {
      console.error(error);
    }
  }, [ setPartnerResources ]);

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <Layout pageTitle="Partner Resources">
      <Box component="section" sx={{ px: 4, py: 2 }}>
        <Typography variant="h5" sx={{ my: 2, fontWeight: 500 }}>
          Partner Resource Center
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 7 }}>
        {/* Left Section with Explore and Accordion */}
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: 7 }}>
          <Box
            component="section"
            sx={{
              minWidth: '1000px',
              maxWidth: '1000px',
              padding: 0,
              display: 'flex',
              flexWrap: 'wrap',
              borderRadius: '4px',
              alignItems: 'stretch',
              border: '1px solid #e1e3e5',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                width: '500px',
                justifyContent: 'center',
                borderRadius: '4px',
              }}
            >
              <Typography variant="body1" sx={{ my: 1 }}>
                Explore a wide range of documents, templates, and policies. Find resources that can help you navigate various aspects of your membership.
              </Typography>
              <Button
                variant="outlined"
                sx={{ my: 2, paddingX: 2, paddingY: 1, width: '100%', maxWidth: '300px' }}
                onClick={() => setShowResourceModal(true)}
              >
                Explore Partner Resource Center
              </Button>
            </Box>
            <Box
              sx={{
                flex: 1,
                ml: 1,
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/tpn_security_lock_rounded.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                borderRadius: '4px',
                right: 0,
                bottom: 0,
                left: '50%',
                width: '50%',
                border: 'none',
              }}
            />
          </Box>
          <Accordion
            sx={{
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
              minWidth: '1000px',
              maxWidth: '1000px',
              padding: 2,
              border: '1px solid #e1e3e5',
              backgroundColor: 'inherit',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: 'inherit' }}
            >
              <Typography variant="h5">Getting Started with Partner Resources</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Watch the explainer video below to understand how to navigate and make the most of the Partner Resource Center.
              </Typography>
              <Box
                component="video"
                controls
                sx={{
                  width: '100%',
                  height: '500px',
                  border: 'none',
                }}
                controlsList="nodownload"
                onContextMenu={(e: any) => e.preventDefault()}
              >
                <source
                  src="https://tpn-public-documents.s3.us-west-1.amazonaws.com/Melody+Partner+Resources_no_bg+(1).mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Right Section - Recommended Resources */}
        <Box
          sx={{
            maxWidth: '280px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            alignSelf: 'flex-start',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              width: '100%',
              fontWeight: 400,
              p: 1,
              px: 2,
              backgroundColor: '#ededef',
              border: '1px solid #e1e3e5',
            }}
          >
            Recommended Resources
          </Typography>
          <Box
            sx={{
              px: 2,
              pb: 2,
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
              border: '1px solid #e1e3e5',
              borderTop: 'none',
            }}
          >
            {loadingResources && (
              <>
                <Skeleton variant="text" animation="pulse" />
                <Skeleton variant="text" animation="pulse" />
                <Skeleton variant="text" animation="pulse"/>
              </>
            )}
            {partnerResources.map((resource) => (
              <Typography
                key={resource.id}
                variant="body1"
                onClick={() => {
                  setShowResourceModal(true);
                  navigate(`/partner-resources/${resource.category.title}/${resource.id}`);
                }}
                sx={{
                  cursor: 'pointer',
                  color: '#0073e6',
                  fontWeight: 500,
                  textDecoration: 'none',
                  '&:hover': {
                    color: '#005bb5',
                    textDecoration: 'underline',
                  },
                }}
              >
                {resource.title}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      <PartnerResourcesModal
        fetchResources={fetchResources}
        partnerResources={partnerResources}
        showResourceModal={showResourceModal}
        handleCloseResourceModal={handleCloseResourceModal}
      />
    </Layout>

  );
};

export default PartnerResources;
