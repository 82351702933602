import { jsPDF } from 'jspdf';
import moment from 'moment';
import { Application, Badge, Company, Contact } from '../interfaces/users.interface';
import { Workflow } from '../interfaces/workflow.interface';
import { workflowLabels } from './workflow';
import getAuthSession from './auth';
import axios from 'axios';

interface GenerateGoldShieldProps {
  company: Company;
  workflow: Workflow;
  site?: Contact;
}

export const selectBadgeFromArray = (badges?:Badge[], allowExpired = false) => {
  if (!badges || badges.length === 0) return undefined;
  const today = moment().unix();
  const filteredBadges = badges.filter(badge => badge && (!allowExpired ? moment(badge.expirationDate).unix() > today : true)).sort((a, b) => moment(b.expirationDate).unix() - moment(a.expirationDate).unix());
  if (filteredBadges.find(b => b.title === 'tpn_assessed')) return filteredBadges.filter(badge => badge.title === 'tpn_assessed')[0];
  if (filteredBadges.find(b => b.title === 'tpn_self_reported')) return filteredBadges.filter(badge => badge.title === 'tpn_self_reported')[0];
  return filteredBadges[0];
};
  // fetches most recent badge of a specific type from a list of workflows
export const recentBadgeByType = (list: Workflow[], type: string) => {
  return list.reduce((acc, wf) => {
    const candidates = (wf.badges as (Badge | number)[])?.filter((b): b is Badge => typeof b !== 'number' && b.title === type);
    if (!candidates || candidates.length === 0) return acc;
    candidates.sort((a, b) => moment(b.awardedDate).unix() - moment(a.awardedDate).unix());
    if (!acc.badgeDate || moment(acc.badgeDate).unix() < moment(candidates[0].awardedDate).unix()) {
      acc.badgeDate = candidates[0].awardedDate;
      acc.badge = candidates[0];
      acc.version = workflowLabels(wf.workflowType);
    }
    return acc;
  }, {
    badgeDate: '',
    badge: undefined as Badge | undefined,
    version: '',
  });
};

const fetchBadgeTitle = (badges?: Badge[], badgeTitle?: string, targetTitle?: string, color?: string) => {
  if (!badges || badges.length === 0) return undefined;
  const badge = badges.find(b => b.title === targetTitle);
  const today = moment().unix();
  if (!badge) return undefined;
  const exp = moment(badge.expirationDate).unix() > today ? 'Expires ' : 'Expired ';
  const version = badgeTitle ? badgeTitle.split(' ')[1] : '';
  return `${color} - ${version} ${exp}${moment(badge.expirationDate).format('MMM D, YYYY')}`;
};

export const fetchGoldBadgeLabel = (badges?: Badge[], badgeTitle?: string) => {
  return fetchBadgeTitle(badges, badgeTitle, 'tpn_assessed', 'Gold');
};

export const fetchBlueBadgeLabel = (badges?: Badge[], badgeTitle?: any) => {
  return fetchBadgeTitle(badges, badgeTitle, 'tpn_self_reported', 'Blue');
};

export const deleteBadge = async (badgeId: number) => {
  const authSession = await getAuthSession();
  await axios.delete(
    `${process.env.REACT_APP_BASE_API}/badges/${badgeId}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return true;
};

export const downloadWithWhiteBackground = (imgSrc: string, fileName: string) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = imgSrc;

  image.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return;

    // Set canvas size to match image
    canvas.width = image.width;
    canvas.height = image.height;

    // Fill the background with white
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the image on top of the white background
    context.drawImage(image, 0, 0);

    // Generate the image and download
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = fileName;
    link.click();
  };
};

export const generateGoldShield = ({
  company,
  site,
  workflow,
}: GenerateGoldShieldProps) => {
  // Create a new image
  const background = new Image();
  background.src = '/assets/GoldShieldStatus.png';
  const padding = 40;
  background.onload = function () {

    // Ensure canvas size matches the image size
    const canvas = document.createElement('canvas');
    canvas.width = background.width + padding * 2;
    canvas.height = background.height + padding * 2;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(background, padding, padding, background.width, background.height);

    // Starting text properties
    ctx.font = '700 60px Montserrat';
    ctx.fillStyle = '#1c2a42';

    let serviceProviderName = company?.name + '' || '';

    const siteName = site?.name;
    const locationName = company.locations.find(location => location.id === workflow?.site)?.name;
    const applicationName = workflow?.application ? (workflow.application as Application).name : '';

    const siteApp = siteName || locationName || applicationName;

    if (!workflow?.badges?.length) return;

    const badges = workflow.badges as Badge[];
    const wfCompDate = badges.find((badge) => typeof badge === 'object' && badge.title === 'tpn_assessed');
    const expirationDate = wfCompDate && typeof wfCompDate === 'object' ? moment(wfCompDate.expirationDate).format('MMM D, YYYY') : '';
    const completedDate = wfCompDate && typeof wfCompDate === 'object' ? moment(wfCompDate.awardedDate).format('MMM D, YYYY') : '';

    ctx.textAlign = 'center';

    // Text props
    const maxWidth = 860;
    const maxLines = 2;
    const lineHeight = 80;
    const x = canvas.width / 2; // Center x pos
    let y = 917; // Starting y pos

    // Truncate with ellipsis if needed
    function truncateLine(canvasText: any, text: string, maxW: string | number) {
      while (canvasText.measureText(text + '...').width > maxW) {
        text = text.slice(0, -1);
      }
      return text + '...';
    }

    // Wraps text into lines, cutting at spaces
    function wrapText(canvasText: any, text: string, maxW: string | number, locMaxLines: number) {
      const words = text.split(' ');
      let line = '';
      const lines = [];

      for (let i = 0; i < words.length; i++) {
        const testLine = line + words[i] + ' ';
        const metrics = canvasText.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxW && line !== '') {
          lines.push(line.trim());
          line = words[i] + ' ';
        } else {
          line = testLine;
        }

        if (lines.length === locMaxLines - 1) {
        // Check if the remaining words fit into last allowed line
          const remainingWords = words.slice(i).join(' ');
          if (canvasText.measureText(remainingWords).width > maxW) {
            lines.push(truncateLine(ctx, remainingWords, maxW));
          } else {
            lines.push(remainingWords);
          }
          break;
        }
      }

      if (lines.length < locMaxLines) {
        lines.push(line.trim());
      }

      return lines;
    }

    // Draw SP name
    const lines = wrapText(ctx, serviceProviderName, maxWidth, maxLines);
    for (let i = 0; i < lines.length && i < maxLines; i++) {
      ctx.fillText(lines[i], x, y + i * lineHeight);
    }

    y += lines.length * lineHeight + 5; // Extra space before site/app name
    if (serviceProviderName.toLowerCase().trim() !== siteApp.toLocaleLowerCase().trim()) {
      ctx.font = '700 55px Montserrat';

      // Draw site/application name
      const siteAppLines = wrapText(ctx, siteApp, maxWidth, maxLines);
      for (let i = 0; i < siteAppLines.length && i < maxLines; i++) {
        ctx.fillText(siteAppLines[i], x, y + i * lineHeight);
      }

      y += siteAppLines.length * lineHeight; // Adjust space before the address section
    }

    if (site) {
      // Draw company address, city, state, and country with smaller font
      ctx.font = '400 42px Montserrat'; // Smaller font size
      ctx.fillStyle = '#283352';

      // Address line(s)
      const addressLines = wrapText(ctx, `${site?.address}`, maxWidth, 3);
      for (let i = 0; i < addressLines.length && i < 3; i++) {
        ctx.fillText(addressLines[i], x, y + i * 55);
      }

      y += addressLines.length * 60; // Move down after address lines

      ctx.font = '500 40px Montserrat'; // Smaller font size
      // City, State line
      ctx.fillText(`${site?.city}, ${site?.state} ${site?.zipcode}`, x, y);
      y += 60; // Move down for the next line

      ctx.font = '600 42px Montserrat'; // Smaller font size
      // Country line
      ctx.fillText(`${site?.country}`, x, y);
    } else if (typeof workflow?.version !== 'number' && workflow?.version?.value) {
      // Version data
      ctx.font = '700 50px Montserrat';
      ctx.fillStyle = '#2a3a52';
      ctx.fillText(`${workflow.version?.value}`, x, y);
    }

    y += 75; // Extra space before dates

    // Min y pos of 1500 for dates
    if (y < 1185) {
      y = 1185;
    }

    // Draw Completed Date
    ctx.font = '700 60px Montserrat';
    ctx.fillStyle = '#1c2a42';
    ctx.fillText('Completed Date:', x, y);
    y += lineHeight;
    ctx.font = '300 65px Montserrat';
    ctx.fillStyle = '#283352';
    ctx.fillText(`${completedDate}`, x, y);
    y += lineHeight + 10; // Extra space before exp date

    // Draw Exp Date
    ctx.font = '700 60px Montserrat';
    ctx.fillStyle = '#1c2a42';
    ctx.fillText('Expiration Date:', x, y);
    y += lineHeight;
    ctx.font = '300 65px Montserrat';
    ctx.fillStyle = '#283352';
    ctx.fillText(`${expirationDate}`, x, y);

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [background.width, background.height],
    });

    const imgData = canvas.toDataURL('image/png');
    doc.addImage(imgData, 'PNG', 0, 0, background.width, background.height);  // Match the dimensions exactly

    const fileName = `${company?.name || 'Company'}_${site?.name || applicationName}_GoldShieldStatus.pdf`;
    doc.save(fileName);
  };
};